const image1 = '/restomat/step1.png'
const image2 = '/restomat/step2.png'
const image3 = '/restomat/step3.png'

export const images: string[] = [image1, image2, image3]

export const imageByIndex = (index: number): string => images[index % images.length]
export const descByIndex = (index: number): string => {
    switch (index) {
        case 0:
            return '1. jídla zadáte k nám'
        case 1:
            return '2. vygeneruje se grafika na sítě a k tisku'
        case 2:
            return '3. automaticky se zveřejní na vašem webu, sociálních sítích a Google profilu'
        default:
            return ''
    }
}
